import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs7() {
  const { t } = useTranslation(["Blogs7"]);

  return (
    <>
      <Header />
      <div className="page-title-area blogss">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("blog7.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("blog7.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("blog7.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("blog7.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog7.heading")}</h2>
                  <p>{t("blog7.para")}</p>
                  <h4 className="blog6-subheading">{t("blog7.subheading")}</h4>
                  <p>{t("blog7.subpara")}</p>
                  <h4 className="blog6-subheading">{t("blog7.head")}</h4>
                  <ul className="blog6-point">
                    <li> {t("blog7.point1")}</li>
                    <p>{t("blog7.para1")}</p>

                    <li> {t("blog7.point2")}</li>
                    <p>{t("blog7.para2")}</p>

                    <li> {t("blog7.point3")}</li>
                    <p>{t("blog7.para3")}</p>

                    <li> {t("blog7.point4")}</li>
                    <p>{t("blog7.para4")}</p>

                    <li> {t("blog7.point5")}</li>
                    <p>{t("blog7.para5")}</p>

                    <li> {t("blog7.point6")}</li>
                    <p>{t("blog7.para6.1")}</p>
                    <p>{t("blog7.para6.2")}</p>
                    <p>{t("blog7.para6.3")}</p>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <h1 style={{ color: "--var-main-color" }}>
                {t("blog7.latestblog.title")}
              </h1>
              <hr />
              <div className="blog-item">
                <Link to="/blog-1">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.latestblog.img1"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-1">{t("blog7.latestblog.heading1")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-1">
                    {t("blog7.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-2">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.latestblog.img2"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-2">{t("blog7.latestblog.heading2")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-2">
                    {t("blog7.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-3">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.latestblog.img3"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-3">{t("blog7.latestblog.heading3")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-3">
                    {t("blog7.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-4">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.latestblog.img4"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-4">{t("blog7.latestblog.heading4")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-4">
                    {t("blog7.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-5">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.latestblog.img5"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-5">{t("blog7.latestblog.heading5")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-5">
                    {t("blog7.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-6">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.latestblog.img6"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-6">{t("blog7.latestblog.heading6")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-6">
                    {t("blog7.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-8">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.latestblog.img8"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-8">{t("blog7.latestblog.heading8")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-8">
                    {t("blog7.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-9">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog7.latestblog.img9"
                    )}`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-9">{t("blog7.latestblog.heading9")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-9">
                    {t("blog7.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs7;
