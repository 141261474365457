import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import { useTranslation } from "react-i18next";

function Shipping() {
  const { t } = useTranslation(["ShippingPolicy"]);

  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area shipping">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("shipping.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("shipping.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("shipping.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("shipping.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <p>{t("shipping.line1")}</p>
              <p>
              {t("shipping.line2")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Shipping;
