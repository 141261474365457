import React, { useState, useRef } from "react";

import { toast, ToastContainer } from "react-toastify";

function AdderessPayment({ setTabIndex, verify }) {
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    window.location.replace(`${verify}`);

    setIsLoading(false);
  };

  return (
    <>
      <div className="about-content mb-2">
        <div className="adderess-card w-100">
          <div className="adderess-section">
            <div className="ticket-body">
              <h4 className="ticket-title">Choose Payment Mode </h4>
              <div className="row">
                <div
                  className="col-md-12 p-0"
                  style={{ backgroundColor: "#1C1B19" }}
                >
                  <div className="p-5 ">
                    <h4>Payment</h4>
                    <p>Simplify payments with UPI</p>

                    <div className="">
                      <button className="place-btn" onClick={(e) => fetch(e)}>
                        Proceed To Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default AdderessPayment;
