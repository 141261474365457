import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import useApiFunction from "../Shared/useApiFunction";
import { toast, ToastContainer } from "react-toastify";

function PaymentStatus() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetchFunc } = useApiFunction();
  const [payData, setPayData] = useState("");
  const navigate = useNavigate();

  const fetchDetails = async (newdata) => {
    if (newdata) {
      const body = {
        merchantTransactionId: newdata,
      };
      const res = await fetchFunc("status", "POST", body);
      if (res.status) {
        setPayData(res.msg);
      }
    } else {
      toast.warning("Something went wrong!!!", {
        autoClose: 2000,
      });

      navigate("/");
    }
  };
  useEffect(() => {
    if (searchParams.get("data")) {
      let newdata = searchParams.get("data");
      fetchDetails(newdata);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Header />
      <section className="about-area mb-5">
        <div className="container">
          <div className="row align-iems-center">
            <div className="col-lg-6">
              <div className="section-title">
                <div className="welcome">
                  <h1>Order Details </h1>
                  <p style={{ color: "#f49f3f" }}>Thank you for ordering!</p>
                  <table
                    className="table table-section"
                    style={{ backgrounColor: "#1c1b19" }}
                  >
                    <tr>
                      <td>Transaction Status:</td>
                      <td>{payData ? payData?.data?.state : "-"}</td>
                    </tr>
                    <tr>
                      <td>OrderId:</td>
                      <td>{payData ? payData?.order_id : "-----"}</td>
                    </tr>
                    <tr>
                      <td>Transaction Id:</td>
                      <td>
                        {payData ? payData?.data?.merchantTransactionId : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Name:</td>
                      <td>{payData ? payData?.name : "-"}</td>
                    </tr>
                    <tr>
                      <td>Phone Number:</td>
                      <td>{payData ? payData?.phone : "-"}</td>
                    </tr>
                    <tr>
                      <td>Product Details:</td>
                      <td>{payData ? payData?.productinfo : "-"}</td>
                    </tr>
                    <tr>
                      <td>Amount:</td>
                      <td>
                        {payData?.data?.amount
                          ? Number(payData?.data?.amount) / 100
                          : "-"}{" "}
                        Rs.
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Payment source:</td>
                      <td>{payData ? payData?.amount + " Rs." : "-"}</td>
                    </tr> */}
                    {/* <tr>
                      <td>Payment mode:</td>
                      <td>{payData ? payData?.mode : "-"}</td>
                    </tr> */}
                  </table>

                  <div
                    className="mt-4 mb-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      className="google-input"
                      style={{
                        width: "80%",
                        position: "relative",
                        display: "flex",
                      }}
                    >
                      <label className="template-input">My Referral Link</label>
                      <div className="searchTerm">
                        <span className="link-box">
                          {`https://tirrentglobal.com/product-details?refid=${payData?.user_id}`}
                        </span>
                      </div>
                      <button
                        className="verify-btn"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://tirrentglobal.com/product-details?refid=${payData?.user_id}`
                          );
                        }}
                        style={{ margin: "0" }}
                      >
                        <i className="fa fa-copy"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 research-development">
              <div className="about-img-wrap">
                <div className="about-img-slider owl-theme">
                  <div className="about-img-item">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/barrel2.png"
                      alt="About"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default PaymentStatus;
