import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import { useTranslation } from "react-i18next";

function TermsOfUse() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Tou"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area termsofuse">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("heading")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("subHeading")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("paraHeading1")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("paraHeading2")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <h2>{t("1.heading")}</h2>
              <p>{t("1.para1")}</p>
              <p>{t("1.para2")}</p>

              <p>{t("1.para3")}</p>

              <h5 className="term-conditions-order-list">{t("2.heading")}</h5>

              <p>{t("2.para1")}</p>
              <p>{t("2.para2")}</p>
              <p>{t("2.para3")}</p>
              <p>{t("2.para4")}</p>
              <h5 className="term-conditions-order-list">{t("3.heading")}</h5>

              <p>{t("3.para1")}</p>
              <h5 className="term-conditions-order-list">{t("4.heading")}</h5>

              <p>{t("4.para1")}</p>
              <h5 className="term-conditions-order-list">{t("5.heading")}</h5>

              <p>{t("5.para1")}</p>
              <p>{t("5.para2")}</p>
              <p>{t("5.para3")}</p>
              <h5 className="term-conditions-order-list">{t("6.heading")}</h5>

              <p>{t("6.para1")}</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default TermsOfUse;
