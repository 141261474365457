import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs8() {
  const { t } = useTranslation(["Blogs9"]);

  return (
    <>
      <Header />
      <div className="page-title-area blogss">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t("img")}`}
                    width="100%"
                  />

                  <h2>{t("heading")}</h2>
                  <p>{t("para")}</p>

                  <ul className="blog6-point">
                    <li> {t("head1")}</li>
                    <p>{t("para1")}</p>

                    <li> {t("head2")}</li>
                    <p>{t("para2")}</p>

                    <li> {t("head3")}</li>
                    <p>{t("para3")}</p>

                    <li> {t("head4")}</li>
                    <p>{t("para4")}</p>

                    <li> {t("head5")}</li>
                    <p>{t("para5")}</p>

                    <li> {t("head6")}</li>
                    <p>{t("para6")}</p>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <h1 style={{ color: "--var-main-color" }}>
                {t("latestblog.title")}
              </h1>
              <hr />
              <div className="blog-item">
                <Link to="/blog-1">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img1"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-1">{t("latestblog.heading1")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-1">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-2">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img2"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-2">{t("latestblog.heading2")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-2">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-3">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img3"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-3">{t("latestblog.heading3")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-3">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-4">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img4"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-4">{t("latestblog.heading4")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-4">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-5">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img5"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-5">{t("latestblog.heading5")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-5">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-6">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img6"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-6">{t("latestblog.heading6")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-6">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-7">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img7"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-7">{t("latestblog.heading7")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-7">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-8">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img8"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-8">{t("latestblog.heading8")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-8">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs8;
