import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import { useTranslation } from "react-i18next";

function Blogs() {
  const { t } = useTranslation(["Blogs"]);

  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area blogs">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy"> {t("blog.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("blog.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("blog.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("blog.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-area blog-area-two pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog-1">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img1"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-1">{t("blog.heading1")}</Link>
                  </h3>
                  <Link to="/blog-1" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="/blog-2">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img2"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-2">{t("blog.heading2")}</Link>
                  </h3>
                  <Link to="/blog-2" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="/blog-3">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img3"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-3">{t("blog.heading3")}</Link>
                  </h3>
                  <Link to="/blog-3" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="/blog-4">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img4"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-4">{t("blog.heading4")}</Link>
                  </h3>
                  <Link to="/blog-4" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog-5">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img5"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-5">{t("blog.heading5")}</Link>
                  </h3>
                  <Link to="/blog-5" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog-6">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img6"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-6">{t("blog.heading6")}</Link>
                  </h3>
                  <Link to="/blog-6" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog-7">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img7"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-7">{t("blog.heading7")}</Link>
                  </h3>
                  <Link to="/blog-7" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog-8">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img8"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-8">{t("blog.heading8")}</Link>
                  </h3>
                  <Link to="/blog-8" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item seperate-blog">
                <Link to="/blog-9">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog.img9"
                    )}`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="/blog-9">{t("blog.heading9")}</Link>
                  </h3>
                  <Link to="/blog-9" className="blog-link">
                    {t("blog.readmore")}
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Blogs;
