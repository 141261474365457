import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import useApiFunction from "./useApiFunction";

function Header() {
  const { t } = useTranslation(["Navbar"]);
  const pathLocation = useLocation();
  const [openList, setOpenList] = useState("");
  const { fetchFunc } = useApiFunction();

  const [userToken, setUserToken] = useState("");
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [toggle, setToggle] = useState(false);
  let counts = 0;
  let date = localStorage.getItem("cart");
  const modalRef = useRef(null);
  const ref = useRef(null);
  const navigate = useNavigate();
  if (data) {
    counts = data.length;
  }
  const logout = (e) => {
    sessionStorage.clear();
    setUserToken("");
  };
  const openMenuList = () => {
    setOpenList(!openList);
  };
  const getToken = () => {
    const userId = sessionStorage.getItem("token");
    setUserToken(userId);
    if (userId) {
      getUser();
    }
  };

  function handelCart() {
    navigate("/cart");
  }
  const getUser = async () => {
    const res = await fetchFunc("get_user", "GET", {});
    if (res.status) {
      setUserData(res.msg);
    } else {
      logout();
    }
  };

  // useEffect(() => {
  //   const handleClickOutside = (e) => {
  //     if (ref.current && !ref.current.contains(e.target)) {
  //       setOpenList(false);
  //     }
  //     if (modalRef.current && !modalRef.current.contains(e.target)) {
  //       setToggle(false);
  //     }
  //   };

  //   document.addEventListener("click", handleClickOutside, true);
  //   // document.addEventListener("click", closeModal, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //     // document.removeEventListener("click", closeModal, true);
  //   };
  // }, []);

  useEffect(() => {
    setData(JSON.parse(date));
    getToken();
  }, [date, userToken]);

  

  return (
    <>
      <div className="navbar-area sticky-top">
        {/* mobile screen */}
        <div className="mobile-nav mean-container">
          <div className="mean-bar">
            <a
              className={`${t(`class.meanmenu-reveal`)}`}
              onClick={openMenuList}
            >
              <span>
                <span>
                  <span></span>
                </span>
              </span>
            </a>
            <Link to="/" className={t("class.logo")}>
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/logo.png"
                width="36%"
                alt="Logo"
              />
            </Link>

            <nav className="mean-nav">
              <ul
                className={
                  openList ? "navbar-nav show-items" : "navbar-nav remove-items"
                }
                ref={ref}
              >
                <li className="nav-item">
                  <Link
                    to="/"
                    className={
                      pathLocation.pathname === "/"
                        ? "nav-link dropdown-toggle active"
                        : "nav-link dropdown-toggle"
                    }
                  >
                    {t("navlinks.home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/about"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/about"
                  >
                    {t("navlinks.about")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/research-and-technology"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/research-and-technology"
                  >
                    {t("navlinks.research")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/products"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/products/0"
                  >
                    {t("navlinks.productDetails")}
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/"
                  >
                    {t("navlinks.suppliers")}
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/gallery"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/gallery"
                  >
                    {t("navlinks.gallery")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      pathLocation.pathname === "/contact"
                        ? "nav-link  active"
                        : "nav-link "
                    }
                    to="/contact"
                  >
                    {t("navlinks.contact")}
                  </Link>
                </li>
              </ul>
              {/* small-scree-login */}
              <div
                className={`${t(
                  "class.side-nav"
                )} d-flex gap-3 align-item-center`}
              >
                {userToken ? (
                  <div className="">
                    <div
                      className="dropdown"
                      onClick={() => {
                        setToggle((prev) => !prev);
                      }}
                      ref={modalRef}
                      
                    >
                      <a
                        className="rounded-circle p-0"
                        href="#"
                        role="button"
                        id="dropdownUser"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="avatar avatar-md avatar-indicators avatar-online">
                          <img
                            alt="avatar"
                            src="SiteTemplate/TirrentGlobal/assets/img/logo----.png"
                            className="rounded-circle"
                          />
                        </div>
                      </a>
                    </div>
                    {toggle && (
                      <div
                        className={`${t("class.dropdown-menu")}`}
                        aria-labelledby="dropdownUser"
                      >
                        <div className="dropdown-item">
                          <div className="">
                            <div className="ml-3 lh-1 user-email">
                              <h5 className="mb-0">
                                {userData.name ? userData.name : "-"}
                              </h5>
                              <p className="mb-0">
                                {userData.email ? userData.email : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-divider"></div>
                        <ul className="list-unstyled">
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/history-previous"
                            >
                              <span className="mr-1">
                                <i
                                  className="fa fa-history"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              &nbsp;{t("userLinks.order")}
                            </Link>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              // onClick={() => directProfile()}
                            >
                              <span className="mr-2">
                                <i className="fa fa-user"></i>
                              </span>
                              &nbsp;{t("userLinks.profile")}
                            </a>
                          </li>
                          <li>
                            <div
                              className="dropdown-item"
                              onClick={(e) => {
                                logout(e);
                              }}
                            >
                              <span className="mr-2">
                                <i className="fa fa-power-off"></i>
                              </span>
                              &nbsp; {t("userLinks.signout")}
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  // <div className="side-nav d-flex align-items-center">
                  <div className="buy-btn-sm">
                    <Link to="/login">{t("button.login")}</Link>{" "}
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>

        <div className="main-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand" style={{ width: "10%" }}>
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/logo.png"
                  className="white-logo"
                  alt="Logo"
                />
              </Link>

              <div
                className="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className={
                        pathLocation.pathname === "/"
                          ? "nav-link dropdown-toggle active"
                          : "nav-link dropdown-toggle"
                      }
                    >
                      {t("navlinks.home")}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/about"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/about"
                    >
                      {t("navlinks.about")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/research-and-technology"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/research-and-technology"
                    >
                      {t("navlinks.research")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/products"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/products/0"
                    >
                      {t("navlinks.productDetails")}
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/"
                    >
                      {t("navlinks.suppliers")}
                    </Link>
                  </li> */}
                   <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/gallery"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/gallery"
                    >
                      {t("navlinks.gallery")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        pathLocation.pathname === "/contact"
                          ? "nav-link  active"
                          : "nav-link "
                      }
                      to="/contact"
                    >
                      {t("navlinks.contact")}
                    </Link>
                  </li>
                </ul>

                <div className="" style={{ marginRight: "10px" }}>
                  <i
                    className="fa-solid fa-cart-shopping cart"
                    value={counts > 0 ? `${counts}` : "0"}
                    onClick={() => handelCart()}
                  ></i>
                </div>

                <div
                  className={`${t("class.side-nav")} d-flex align-items-center`}
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <div className="buy-btn lg">
                    <Link to="/products/0 " className="p-0">
                      {t("button.buynow")}
                    </Link>{" "}
                    <i
                      className="fa fa-shopping-cart"
                      style={{ marginLeft: "0em" }}
                    ></i>
                  </div>
                </div>

                {userToken ? (
                  
                    <ul className="list-unstyled">
                      <li
                        className="dropdown ml-2"
                        onClick={() => setToggle((prev) => !prev)}
                        ref={modalRef}
                      >
                        <a
                          className="rounded-circle "
                          href="#"
                          role="button"
                          id="dropdownUser"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div
                            className="avatar avatar-lg avatar-indicators avatar-online"
                            style={{ marginLeft: "10px" }}
                          >
                            <img
                              alt="avatar"
                              src="SiteTemplate/TirrentGlobal/assets/img/logo----.png"
                              className="rounded-circle"
                            />
                          </div>
                        </a>

                        {toggle && (
                          <div
                            className={`${t(`class.dropdown-menu`)}`}
                            aria-labelledby="dropdownUser"
                          >
                            <div className="dropdown-item">
                              <div className="">
                                <div className="ml-3 lh-1 user-email">
                                  <h5 className="mb-0">
                                    {userData.name ? userData.name : "-"}
                                  </h5>
                                  <p className="mb-0">
                                    {userData.email ? userData.email : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-divider"></div>
                            <div className="">
                              <ul className="list-unstyled">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/history-previous"
                                  >
                                    <span className="mr-1">
                                      <i
                                        className="fa fa-history"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    &nbsp; {t("userLinks.order")}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="dropdown-divider"></div>

                            <ul className="list-unstyled">
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  // onClick={() => directProfile()}
                                >
                                  <span className="mr-1">
                                    <i className="fa fa-user"></i>
                                  </span>
                                  &nbsp; {t("userLinks.profile")}
                                </a>
                              </li>
                            </ul>

                            <div className="dropdown-divider"></div>
                            <ul className="list-unstyled">
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={logout}
                                >
                                  <span className="mr-2">
                                    <i className="fa fa-power-off"></i>
                                  </span>
                                  &nbsp; {t("userLinks.signout")}
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                      </li>
                    </ul>
                  
                ) : (
                  <div
                    className={`side-nav ${t(
                      "class.side-nav"
                    )} d-flex align-items-center mr-2`}
                  >
                    <div className="buy-btn lg">
                      <Link to="/login" className="p-0 ">
                        {t("button.login")}
                      </Link>{" "}
                    </div>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Header;
