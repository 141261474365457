import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
// import SideNavIcon from "./SideNavIcon";
import pdfFile from "../../TirrentBooster.pdf";

function Footer() {
  const { i18n, t } = useTranslation(["Footer"]);
  const [openDrop, setOpenDrop] = useState(false);
  const [language, setLanguage] = useState("");

  const lang = useRef();

  const getLanguage = (e) => {
    const language = localStorage.getItem("i18nextLng") || "en";
    setLanguage(language);
  };

  const changeLanguage = (e) => {
    setOpenDrop((prv) => !prv);
    i18n.changeLanguage(e.currentTarget.id);
    setLanguage(e.currentTarget.id);
  };

  useEffect(() => {
    getLanguage();
  }, []);

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <>
      <footer className="pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-logo">
                  <a className="logo-link" href="index.html">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/logo.png"
                      className="main-logo"
                      alt="img"
                    />
                  </a>
                  <p>{t("footer.description")}</p>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/tirrentglobal"
                        target="_blank"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/tirrentglobal"
                        target="_blank"
                      >
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/tirrentglobal/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/tirrent_global" target="_blank">
                        <i className="fa-brands fa-telegram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCCQfdl9HuHvwvPCeBvl1png"
                        target="_blank"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://in.pinterest.com/tirrentglobal/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-service">
                  <h3>{t("footer.mainLinks.title")}</h3>
                  <ul>
                    <li>
                      <Link to="/">{t("footer.mainLinks.home")}</Link>
                    </li>
                    <li>
                      <Link to="/about">{t("footer.mainLinks.about")}</Link>
                    </li>
                    <li>
                      <Link to="/research-and-technology">
                        {t("footer.mainLinks.research")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/products">
                        {t("footer.mainLinks.productDetails")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/oursuppliers">
                        {t("footer.mainLinks.suppliers")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">{t("footer.mainLinks.contact")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-service">
                  <h3>{t("footer.usefulLinks.title")}</h3>
                  <ul>
                    <li>
                      <Link to="#">{t("footer.usefulLinks.policy")}</Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.usefulLinks.seller")}</Link>
                    </li>
                    <li>
                      <Link to="/">{t("footer.usefulLinks.procedure")}</Link>
                    </li>
                    <li>
                      <Link to="/">{t("footer.usefulLinks.terms")}</Link>
                    </li>

                    <li>
                      <Link to="/faq">{t("footer.usefulLinks.faq")}</Link>
                    </li>
                    <li>
                      <Link to="/blogs">{t("footer.usefulLinks.blogs")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="footer-item">
                <div className="footer-service">
                  <h3>{t("footer.policies.title")}</h3>
                  <ul>
                    <li>
                      <Link to="#">{t("footer.policies.privacy")}</Link>
                    </li>
                    <li>
                      <Link to="/cancellation-policy">
                        {t("footer.policies.exchange")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/shipping-policy">
                        {t("footer.policies.shipping")}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">{t("footer.policies.termpolicy")}</Link>
                    </li>
                    <li>
                      <Link to="/termsofuse">
                        {t("footer.policies.termofuse")}
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        window.open(pdfFile, "_blank");
                      }}
                    >
                      <Link to="/SiteTemplate/TirrentGlobal/assets/img/11.png">
                        {t("footer.policies.doc")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/social-resposibility">
                        {t("footer.policies.social")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-item">
            <p>
              {t("footer.copyright.coyp")}
              <Link to="/">&nbsp; {t("footer.copyright.span")}</Link>
            </p>
          </div>
        </div>
      </div>
      {/* <SideNavIcon /> */}

      <div id="toTop" className="back-to-top-btn" style={{ display: "block" }}>
        <div className="dropdown">
          {openDrop && (
            <div
              className="user-email drop m-1 hover"
              style={{
                transition: "all .8s cubic-bezier(.3,1.1,.4,1.1)",
              }}
            >
              <div
                id="en"
                onClick={(e) => {
                  changeLanguage(e);
                }}
                style={{ padding: "6px 15px" }}
              >
                <img
                  alt="flag-img"
                  src="SiteTemplate/TirrentGlobal/assets/img/flag/usa_flag.png"
                  style={{ margin: "0 6px 0 0" }}
                />
                <span style={{ margin: "0 4px 0 0" }}>EN</span>
              </div>

              <div
                id="ar"
                onClick={(e) => {
                  changeLanguage(e);
                }}
                style={{ padding: "6px 15px" }}
              >
                <img
                  alt="flag-img"
                  src="SiteTemplate/TirrentGlobal/assets/img/flag/ar_flag.jpg"
                  style={{ margin: "0 6px 0 0" }}
                />
                <span style={{ margin: "0 4px 0 0" }}>AR </span>
              </div>
            </div>
          )}
          <div
            className="d-flex drop"
            onClick={() => {
              setOpenDrop((prev) => !prev);
            }}
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: "6px 15px",
            }}
          >
            <div style={{ margin: "0 6px 0 0" }}>
              {language === "ar" ? (
                <img
                  src="SiteTemplate/TirrentGlobal/assets/img/flag/ar_flag.jpg"
                  alt="flag"
                  width="93%"
                />
              ) : (
                <img
                  src="SiteTemplate/TirrentGlobal/assets/img/flag/usa_flag.png"
                  alt="flag"
                  width="93%"
                />
              )}
            </div>
            <span style={{ margin: "0 4px 0 0" }}>
              {language.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
