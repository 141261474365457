import React, { useEffect, useRef, useState } from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

import { Link } from "react-router-dom";
import CircularProgress from "./CircularProgress";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Particle from "../Shared/Particle";

import { useTranslation } from "react-i18next";
function Home() {
  const { t } = useTranslation(["home"]);
  const myRef = useRef();
  const [startCounter, setStartCounter] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setStartCounter(entry.isIntersecting);
    });
    observer.observe(myRef.current);
  }, []);
  const responsive = {
    0: { items: 1 },
    350: { items: 2 },
    700: { items: 3 },
    1050: { items: 4 },
  };

  const items = [
    <div className="item foreign-item" defaultValue="1">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-booste-jcb.jpg"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.first")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="2">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-booste-car.jpg"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.second")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="3">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/machine.png"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.third")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="4">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-booste-bus.jpg"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.fourth")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="5">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/tirrent-booste-tractor.jpg"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.fifth")}</span>
      </div>
    </div>,
    <div className="item foreign-item" defaultValue="6">
      <a href="project-details.html">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/Untitled design (6).png"
          alt="Foreign"
        />
      </a>
      <div className="foreign-bottom">
        <h3>
          <a href="project-details.html">{t("seventh.pointsHeading")}</a>
        </h3>
        <span>{t("seventh.points.sixth")}</span>
      </div>
    </div>,
    // <div className="item" data-value="2">2</div>,
    // <div className="item" data-value="3">3</div>,
    // <div className="item" data-value="4">4</div>,
    // <div className="item" data-value="5">5</div>,
  ];
  const techResponsive = {
    0: { items: 1 },
    600: { items: 2 },
    700: { items: 2 },
    1000: { items: 3 },
  };
  const techItems = [
    <li style={{ marginLeft: "20px" }}>
      <a className="card-img-slider">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/Tirrent Particles.png"
          className="card__image"
        />
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img
              className="card__thumb"
              src="/SiteTemplate/TirrentGlobal/assets/img/Alltechno.png"
            />
            <div className="card__header-text">
              <h3 className="card__title">{t("third.first.heading")}</h3>
            </div>
          </div>
          <p className="card__description">{t("third.first.para")}</p>
        </div>
      </a>
    </li>,
    <li style={{ marginLeft: "20px" }}>
      <a className="card-img-slider">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/Anti-Aging Property.png"
          className="card__image"
        />
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img
              className="card__thumb"
              src="/SiteTemplate/TirrentGlobal/assets/img/Alltechno.png"
            />
            <div className="card__header-text">
              <h3 className="card__title"> {t("third.second.heading")}</h3>
            </div>
          </div>
          <p className="card__description">{t("third.second.para")}</p>
        </div>
      </a>
    </li>,
    <li style={{ marginLeft: "20px" }}>
      <a className="card-img-slider">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/Quantum Technology.png"
          className="card__image"
          alt="img"
        />
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img
              className="card__thumb"
              src="/SiteTemplate/TirrentGlobal/assets/img/Alltechno.png"
              alt="img"
            />
            <div className="card__header-text">
              <h3 className="card__title">{t("third.third.heading")}</h3>
            </div>
          </div>
          <p className="card__description">{t("third.third.para")}</p>
        </div>
      </a>
    </li>,
    <li style={{ marginLeft: "20px" }}>
      <a className="card-img-slider">
        <img
          src="/SiteTemplate/TirrentGlobal/assets/img/viscoelasticity.png"
          className="card__image"
          alt="img"
        />
        <div className="card__overlay">
          <div className="card__header">
            <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg>
            <img
              className="card__thumb"
              src="/SiteTemplate/TirrentGlobal/assets/img/Alltechno.png"
              alt="img"
            />
            <div className="card__header-text">
              <h3 className="card__title">{t("third.fourth.heading")}</h3>
            </div>
          </div>
          <p className="card__description">{t("third.fourth.para")}</p>
        </div>
      </a>
    </li>,
  ];
  return (
    <>
      <Header />

      <div className="banner-area banner-img-one">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-md-12 d-flex align-items-center">
                  <div className="banner-content">
                    <h1>{t("Banner.heading")}</h1>
                    <p>{t("Banner.description")}</p>
                    <Link to="/product-details" className="cmn-btn">
                      {t("Banner.button")}
                      <i
                        className="fa fa-shopping-cart"
                        style={{ marginLeft: "8px" }}
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6 d-flex align-items-center">
                  <div className="banner-content">
                    <h1 style={{ textAlign: "left" }}>
                      Welcome To <br />
                      Tirrent Global
                    </h1>
                    <p style={{ textAlign: "left" }}>
                      First time in India, A revolutionary American product that
                      arrests aging of your vehicle's engines.
                    </p>
                    <Link to="/productdetail" className="cmn-btn">
                      Buy Now
                      <i
                        className="fa fa-shopping-cart"
                        style={{ marginLeft: "8px" }}
                      ></i>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <div className="banner-content">
                    <video width="100%" height="100%" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="about-tirrent">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">{t("first.subHeading")}</span>

            <h2>{t("first.heading")}</h2>
          </div>
          <div className="row">
            <div className="col-lg-3 about-img-product">
              {/* <div className="header__left">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/product.png"
                  alt="img"
                  width="80%"
                />
              </div> */}
              <article class="card23">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/products/tirrentbooster.png"
                  alt="tirrent-booter"
                  width="100%"
                />
                <div class="card__content | flow">
                  <div class="card__content--container | flow">
                    <h2 class="card__title" style={{ color: "#f99f36" }}>
                      {t("first.1.h")}
                    </h2>
                    <p
                      class="card__description1"
                      style={{ fontSize: "14px", margin: "5px" }}
                    >
                      {t("first.1.p")}
                    </p>
                  </div>
                  <button class="card__button">
                    <Link to="/products/0">{t("first.readmore")}</Link>
                  </button>
                </div>
              </article>
            </div>
            <div className="col-lg-3 about-img-product">
              {/* <div className="header__left">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/product.png"
                  alt="img"
                  width="80%"
                />
              </div> */}
              <article class="card23">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/products/mileage booster--.jpg"
                  alt="img"
                  width="100%"
                />
                <div class="card__content | flow">
                  <div class="card__content--container | flow">
                    <h2 class="card__title" style={{ color: "#f99f36" }}>
                      {t("first.2.h")}
                    </h2>
                    <p
                      class="card__description1"
                      style={{ fontSize: "14px", margin: "5px" }}
                    >
                      {t("first.2.p")}
                    </p>
                  </div>
                  <button class="card__button">
                    <Link to="/products/1">{t("first.readmore")}</Link>
                  </button>
                </div>
              </article>
            </div>

            <div className="col-lg-3 about-img-product">
              {/* <div className="header__left">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/product.png"
                  alt="img"
                  width="80%"
                />
              </div> */}
              <article class="card23">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/products/spray.png"
                  alt="img"
                  width="100%"
                />
                <div class="card__content | flow">
                  <div class="card__content--container | flow">
                    <h2 class="card__title" style={{ color: "#f99f36" }}>
                      {t("first.3.h")}
                    </h2>
                    <p
                      class="card__description1"
                      style={{ fontSize: "14px", margin: "5px" }}
                    >
                      {t("first.3.p")}
                    </p>
                  </div>
                  <button class="card__button">
                    <Link to="/products/2">{t("first.readmore")}</Link>
                  </button>
                </div>
              </article>
            </div>
            <div className="col-lg-3 about-img-product">
              {/* <div className="header__left">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/product.png"
                  alt="img"
                  width="80%"
                />
              </div> */}
              <article class="card23">
                <img
                  src="./SiteTemplate/TirrentGlobal/assets/img/products/grease.jpg"
                  alt="img"
                  width="100%"
                />
                <div class="card__content | flow">
                  <div class="card__content--container | flow">
                    <h2 class="card__title" style={{ color: "#f99f36" }}>
                      {t("first.4.h")}
                    </h2>
                    <p
                      class="card__description1"
                      style={{ fontSize: "14px", margin: "5px" }}
                    >
                      {t("first.4.p")}
                    </p>
                  </div>
                  <button class="card__button">
                    <Link to="/products/3">{t("first.readmore")}</Link>
                  </button>
                </div>
              </article>
            </div>
            {/* <div className="col-lg-6 about-content">
              <div className="header__right order-md">
                <div className="header__head">
                  <div className="empty header__empty">
                    <a className="header__tag">
                      <i className="fa-solid fa-can-food"></i>
                      {t("first.subHeading")}
                    </a>
                  </div>
                </div>
                <h1>{t("first.heading")}</h1>
                <p>{t("first.firstPara")}</p>
                <p>{t("first.secondPara")}</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <section
        className=" about-area about-area-two pt-100"
        style={{ background: "#000" }}
      >
        <div className="container">
          <div className="row align-iems-center">
            <div className="col-lg-6 american-llc">
              <div className="section-title">
                <span className="sub-title">{t("second.subHeading")}</span>

                <h2>{t("second.heading")}</h2>
              </div>
              <div className="about-content">
                <p>{t("second.para")}</p>

                <Link to="/about" className="cmn-btn">
                  {t("second.button")}
                  <i className="fa fa-right-arrow-lt"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 american-img">
              <div className="about-img">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/about-hom.png"
                  alt="About"
                />
                <img
                  className="bounce"
                  src="/SiteTemplate/TirrentGlobal/assets/img/en (1).png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="offer-area pt-100 pb-100">
        <div className="container our-technology">
          <div className="section-title">
            <span className="sub-title">{t("third.subHeading")}</span>
            <h2>{t("third.heading")}</h2>
          </div>
          {/* <div className="offer-slider owl-theme"> */}
          <ul className="cards">
            <AliceCarousel
              items={techItems}
              responsive={techResponsive}
              controlsStrategy="alternate"
              autoPlay={true}
              autoPlayInterval={1200}
              infinite={true}
              animationDuration={1500}
              fadeOutAnimation={true}
              disableDotsControls={true}
            />
          </ul>
        </div>
        {/* </div> */}
      </section>

      <section className="service-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">{t("fourth.subHeading")} </span>
            <h2>
              {t("fourth.heading")}
              <span> {t("fourth.spanHeading")}</span>
            </h2>
          </div>
          <div className="row">
            <div className="offset-lg-2 col-lg-4 col-md-6 col-sm-6  ">
              <div className="service-item suppliment">
                <i className="fa fa-gears"></i>
                <h3>
                  <a> {t("fourth.firstPoint.heading")}</a>
                </h3>
                <p>{t("fourth.firstPoint.para")}</p>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 col-sm-6  ">
              <div className="service-item suppliment">
                <i className="fa fa-atom"></i>

                <h3>
                  <a>{t("fourth.secondPoint.heading")}</a>
                </h3>
                <p>{t("fourth.secondPoint.para")}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-2 col-lg-4 col-md-6 col-sm-6  ">
              <div className="service-item suppliment">
                <i className="fa-solid fa-hourglass"></i>
                <h3>
                  <a>{t("fourth.thirdPoint.heading")}</a>
                </h3>
                <p>{t("fourth.thirdPoint.para")}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6  ">
              <div className="service-item suppliment">
                <i className="fa-solid fa-hand-holding-dollar"></i>
                <h3>
                  <a> {t("fourth.forthPoint.heading")}</a>
                </h3>
                <p>{t("fourth.forthPoint.para")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="job-area job-area-two">
        <div className="container-fluid">
          <div className="row m-0">
            <div className="col-lg-6">
              <div className="job-img">
                <img
                  src="/SiteTemplate/TirrentGlobal/assets/img/Technology.jpg"
                  alt="Job"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="job-content">
                <div className="section-title">
                  <h2> {t("fifth.heading")}</h2>
                </div>
                <p>{t("fifth.firstPara")}</p>
                <p>{t("fifth.secondPara")}</p>
                <ul>
                  <li>
                    <i
                      className="fa fa-check"
                      style={{ fontSize: "17px", marginRight: "5px" }}
                    ></i>{" "}
                    {t("fifth.points.first")}
                  </li>
                  <li>
                    <i
                      className="fa fa-check"
                      style={{ fontSize: "17px", marginRight: "5px" }}
                    ></i>{" "}
                    {t("fifth.points.second")}
                  </li>
                  <li>
                    <i
                      className="fa fa-check"
                      style={{ fontSize: "17px", marginRight: "5px" }}
                    ></i>{" "}
                    {t("fifth.points.third")}
                  </li>
                  <li>
                    <i
                      className="fa fa-check"
                      style={{ fontSize: "17px", marginRight: "5px" }}
                    ></i>{" "}
                    {t("fifth.points.fourth")}
                  </li>
                  <li>
                    <i
                      className="fa fa-check"
                      style={{ fontSize: "17px", marginRight: "5px" }}
                    ></i>{" "}
                    {t("fifth.points.fifth")}
                  </li>
                  <li>
                    <i
                      className="fa fa-check"
                      style={{ fontSize: "17px", marginRight: "5px" }}
                    ></i>{" "}
                    {t("fifth.points.sixth")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="counter-area pt-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">{t("sixth.subHeading")}</span>
            <h2 style={{ color: "white" }}>{t("sixth.heading")}</h2>
          </div>
          <div className="row align-iems-center">
            <div className="col-lg-12">
              <div className="row" ref={myRef}>
                <div className="col-md-12 d-flex justify-content-center">
                  <img
                    src={`./SiteTemplate/TirrentGlobal/assets/img/${t(
                      "sixth.img"
                    )}.png`}
                    className="verticalChart"
                    style={{ width: "75%" }}
                  />
                  <img
                    src="./SiteTemplate/TirrentGlobal/assets/img/Engine's.png"
                    className="sm-screen-quality"
                  />
                  {/* <div className="verticalChart">
                    <div className="singleBar singleBar-sm">
                      <div className="title topic-bar-xl engine-life-head">
                        Engine's Life
                      </div>
                      <div className="bar">
                        <div className="value" style={{ height: "75%" }}>
                          <span
                            style={{
                              display: "inline",
                            }}
                          >
                            10 Times
                          </span>
                        </div>
                      </div>

                      <div className="title topic-bar-sm">Engine's Life</div>
                    </div>

                    <div className="singleBar singleBar-sm">
                      <div className="title topic-bar-xl save-fuel">Save Fuel</div>
                      <div className="bar">
                        <div className="value" style={{ height: "25%" }}>
                          <span
                            style={{
                              display: "inline",
                            }}
                          >
                            25%
                          </span>
                        </div>
                      </div>

                      <div className="title topic-bar-sm">Save Fuel</div>
                    </div>

                    <div className="singleBar singleBar-sm">
                      <div className="title topic-bar-xl performence">
                        Performance
                      </div>
                      <div className="bar">
                        <div className="value" style={{ height: "60%" }}>
                          <span
                            style={{
                              display: "inline",
                            }}
                          >
                            60%
                          </span>
                        </div>
                      </div>

                      <div className="title topic-bar-sm">Performance</div>
                    </div>
                    <div className="singleBar singleBar-sm">
                      <div className="title topic-bar-xl reduce-friction">
                        Reduce Friction
                      </div>
                      <div className="bar">
                        <div className="value" style={{ height: "95%" }}>
                          <span
                            style={{
                              display: "inline",
                            }}
                          >
                            95%
                          </span>
                        </div>
                      </div>

                      <div className="title topic-bar-sm">Reduce Friction</div>
                    </div>

                    <div className="singleBar singleBar-sm">
                      <div className="title topic-bar-xl Reduce-metal">
                        Reduce Metal Ware
                      </div>
                      <div className="bar">
                        <div className="value" style={{ height: "85%" }}>
                          <span
                            style={{
                              display: "inline",
                            }}
                          >
                            85%
                          </span>
                        </div>
                      </div>

                      <div className="title durable-fix topic-bar-sm">
                        Reduce Metal Ware
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="foreign-area">
        <div className="container-fluid">
          <div className="foreign-slider owl-theme ">
            <AliceCarousel
              items={items}
              responsive={responsive}
              controlsStrategy="alternate"
              autoPlay={true}
              autoPlayInterval={1200}
              infinite={true}
              animationDuration={1500}
              fadeOutAnimation={true}
              disableDotsControls={true}
            />
          </div>
        </div>
      </div>

      <section className="benefit-area benefit-area-two">
        <div className="container-fluid">
          <div className="row m-0">
            <div className="col-lg-6 col-sm-12">
              <div className="benefit-content">
                <div className="section-title">
                  <h2>{t("eight.heading")}</h2>
                </div>
                <p>{t("eight.para")}</p>
                <div className="row m-0">
                  <div className="col-sm-6 col-lg-6">
                    <div
                      className="benefit-inner"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <img src="./SiteTemplate/TirrentGlobal/assets/img/icon-application.png" />
                      <h4>{t("eight.points.first")}</h4>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div
                      className="benefit-inner"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <img src="./SiteTemplate/TirrentGlobal/assets/img/icon-chemistry.png" />

                      <h4>{t("eight.points.second")}</h4>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-6">
                    <div
                      className="benefit-inner"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <img src="./SiteTemplate/TirrentGlobal/assets/img/icon-brand.png" />

                      <h4>{t("eight.points.third")}</h4>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6">
                    <div
                      className="benefit-inner"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <img src="./SiteTemplate/TirrentGlobal/assets/img/icon-industry.png" />
                      <h4>{t("eight.points.fourth")}</h4>
                    </div>
                  </div>
                </div>
                <a className="cmn-btn" href="#">
                  {t("eight.button")}
                  <i className="bx bx-right-arrow-alt"></i>
                </a>
                <div className="benefit-shape">
                  <img
                    src="assets/img/home-one/benefit-shape.png"
                    alt="Benefit"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="benefits-img">
                {/* <img src="./SiteTemplate/TirrentGlobal/assets/img/header-img-1.jpg" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="partner-area pb-100">
        <div className="container">
          <div className="partner-wrap">
            <div className="partner-shape">
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/home-one/partner-shape.png"
                alt="Partner"
                width="85%"
              />
            </div>

            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="partner-content">
                  <div className="section-title">
                    <h2>{t("ninth.heading")}</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="partner-btn">
                  <Link to="/contact" className="cmn-btn">
                    {t("ninth.button")}
                    <i
                      className="fa-solid fa-chevron-right"
                      style={{ marginLeft: "8px" }}
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-area blog-area-two pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">{t("tenth.subHeading")}</span>
            <h2>{t("tenth.heading")}</h2>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="blog-1">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "tenth.img.1"
                    )}.png`}
                    alt="Blog"
                    width="100%"
                  />
                </Link>

                {/* <span>20 Aug 2020</span> */}
                <div className="blog-inner">
                  <h3>
                    <Link to="blog-1">{t("tenth.points.first")}</Link>
                  </h3>
                  <Link className="blog-link" to="blog-1">
                    {t("tenth.pointsSubHeading")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="blog-2">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "tenth.img.2"
                    )}.png`}
                    alt="Blog"
                    width="100%"
                  />
                </Link>
                <div className="blog-inner">
                  <h3>
                    <Link to="blog-2">{t("tenth.points.second")}</Link>
                  </h3>
                  <Link className="blog-link" to="blog-2">
                    {t("tenth.pointsSubHeading")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <Link to="blog-3">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "tenth.img.3"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>

                <div className="blog-inner">
                  <h3>
                    <Link to="blog-3">{t("tenth.points.third")}</Link>
                  </h3>
                  <Link className="blog-link" to="blog-3">
                    {t("tenth.pointsSubHeading")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className=""
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Link to="/blogs" className="cmn-btn">
              {t("tenth.pointsSubHeading")}
              <i
                className="fa-solid fa-chevron-right"
                style={{ marginLeft: "8px" }}
              ></i>
            </Link>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Home;
