import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs2() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs2"]);
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <>
        <Header />
        <div className="page-title-area blogss">
          <div className="d-table">
            <div className="d-table-cell">
              <h2 className="policy">{t("blogs3.title")}</h2>
              <div className="container">
                <div className="title-item">
                  <h2>{t("blogs3.title")}</h2>
                  <ul>
                    <li>
                      <Link to="/">{t("blogs3.span")}</Link>
                    </li>
                    <li>
                      <span>/</span>
                    </li>
                    <li>{t("blogs3.title")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="blog-details-area ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 main-blog">
                <div className="blog-details-item">
                  <div className="blog-details-name">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "blogs3.img"
                      )}.png`}
                      width="100%"
                    />

                    <h2>{t("blogs3.heading")}</h2>
                    <p>{t("blogs3.line1")}</p>
                    <p>{t("blogs3.line2")}</p>
                    <p>{t("blogs3.line3")}</p>

                    <ol className="blog1-point">
                      <li>{t("blogs3.point1")}</li>

                      <p>{t("blogs3.point1.1")}</p>
                      <p>{t("blogs3.point1.2")}</p>
                      <p>{t("blogs3.point1.3")}</p>
                      <p>{t("blogs3.point1.4")}</p>
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/smoke-prevent.png"
                          width="40%"
                          className="blogs-mini-img"
                        />
                      </div>

                      <li>{t("blogs3.point2")}</li>

                      <p>{t("blogs3.point2.1")}</p>
                      <p>{t("blogs3.point2.2")}</p>
                      <p>{t("blogs3.point2.3")}</p>
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="https://www.busrepair.ae/wp-content/uploads/2019/11/Oil-change-Sharjah.png"
                          width="40%"
                          className="blogs-mini-img"
                        />
                      </div>
                      <li>{t("blogs3.point3")}</li>

                      <p>{t("blogs3.point3.1")}</p>

                      <p>{t("blogs3.point3.2")}</p>
                      <p>{t("blogs3.point3.3")}</p>
                      <p>{t("blogs3.point3.4")}</p>
                      <p>{t("blogs3.point3.5")}</p>
                      <p>{t("blogs3.point3.6")}</p>
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/cooleant-.png"
                          width="40%"
                          className="blogs-mini-img"
                        />
                      </div>
                      <li>{t("blogs3.point4")}</li>

                      <p>{t("blogs3.point4.1")}</p>
                      <p>{t("blogs3.point4.2")}</p>
                      <p>{t("blogs3.point4.3")}</p>
                      <p>{t("blogs3.point4.4")}</p>
                      <div
                        className=""
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          src="/SiteTemplate/TirrentGlobal/assets/img/change-oil-remove.png"
                          width="40%"
                          className="blogs-mini-img"
                        />
                      </div>

                      <li>{t("blogs3.point5")}</li>

                      <p>{t("blogs3.point5.1")}</p>
                      <p>{t("blogs3.point5.2")}</p>
                      <h3 style={{ color: "#F89F31", fontWeight: "600" }}>
                        {t("blogs3.point6")}
                      </h3>

                      <p>{t("blogs3.point6.1")}</p>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <h1 style={{ color: "--var-main-color" }}>
                  {t("lastestblog.title")}
                </h1>
                <hr />

                <div className="blog-item">
                  <Link to="/blog-1">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "latestblog.img1"
                      )}.png`}
                      alt="Blog"
                    />
                  </Link>
                  <div className="blog-inner1">
                    <h3>
                      <Link to="/blog-1">{t("latestblog.heading1")}</Link>
                    </h3>
                    <Link className="blog-link1" to="/blog-1">
                      {t("latestblog.readmore")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="blog-item">
                  <Link to="/blog-3">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "latestblog.img3"
                      )}.png`}
                      alt="Blog"
                    />
                  </Link>
                  <div className="blog-inner1">
                    <h3>
                      <Link to="/blog-3">{t("latestblog.heading3")}</Link>
                    </h3>
                    <Link className="blog-link1" to="/blog-3">
                      {t("latestblog.readmore")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="blog-item">
                  <Link to="/blog-4">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "latestblog.img4"
                      )}.png`}
                      alt="Blog"
                    />
                  </Link>
                  <div className="blog-inner1">
                    <h3>
                      <Link to="/blog-4">{t("latestblog.heading4")}</Link>
                    </h3>
                    <Link className="blog-link1" to="/blog-4">
                      {t("latestblog.readmore")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="blog-item">
                  <Link to="/blog-5">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "latestblog.img5"
                      )}.png`}
                      alt="Blog"
                    />
                  </Link>
                  <div className="blog-inner1">
                    <h3>
                      <Link to="/blog-5">{t("latestblog.heading5")}</Link>
                    </h3>
                    <Link className="blog-link1" to="/blog-5">
                      {t("latestblog.readmore")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>

                <div className="blog-item">
                  <Link to="/blog-6">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "latestblog.img6"
                      )}.png`}
                      alt="Blog"
                    />
                  </Link>
                  <div className="blog-inner1">
                    <h3>
                      <Link to="/blog-6">{t("latestblog.heading6")}</Link>
                    </h3>
                    <Link className="blog-link1" to="/blog-6">
                      {t("latestblog.readmore")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="blog-item">
                  <Link to="/blog-7">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "latestblog.img7"
                      )}.png`}
                      alt="Blog"
                    />
                  </Link>
                  <div className="blog-inner1">
                    <h3>
                      <Link to="/blog-7">{t("latestblog.heading7")}</Link>
                    </h3>
                    <Link className="blog-link1" to="/blog-7">
                      {t("latestblog.readmore")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>

                <div className="blog-item">
                  <Link to="/blog-8">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "latestblog.img8"
                      )}.png`}
                      alt="Blog"
                    />
                  </Link>
                  <div className="blog-inner1">
                    <h3>
                      <Link to="/blog-8">{t("latestblog.heading8")}</Link>
                    </h3>
                    <Link className="blog-link1" to="/blog-8">
                      {t("latestblog.readmore")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
                <div className="blog-item">
                  <Link to="/blog-9">
                    <img
                      src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                        "latestblog.img9"
                      )}`}
                      alt="Blog"
                    />
                  </Link>
                  <div className="blog-inner1">
                    <h3>
                      <Link to="/blog-9">{t("latestblog.heading9")}</Link>
                    </h3>
                    <Link className="blog-link1" to="/blog-9">
                      {t("latestblog.readmore")}
                      <i className="fa fa-angle-double-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </div>
  );
}

export default Blogs2;
