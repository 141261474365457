import React from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "../Shared/Modal";

function Gallery() {
  const { t } = useTranslation(["Gallery"]);
  const dataImg = [
    {
      1: "SiteTemplate/TirrentGlobal/assets/img/gallery/Bikes.jpeg",
      2: "SiteTemplate/TirrentGlobal/assets/img/gallery/Cars.jpeg",
      3: "SiteTemplate/TirrentGlobal/assets/img/gallery/Deserts.jpeg",
      4: "SiteTemplate/TirrentGlobal/assets/img/gallery/Heavy Machines.jpeg",
      5: "SiteTemplate/TirrentGlobal/assets/img/gallery/Marine.jpeg",
      6: "SiteTemplate/TirrentGlobal/assets/img/gallery/1.png",
    },
    {
      1: "SiteTemplate/TirrentGlobal/assets/img/gallery/2.png",
      2: "SiteTemplate/TirrentGlobal/assets/img/gallery/3.png",
      3: "SiteTemplate/TirrentGlobal/assets/img/gallery/4.png",
      4: "SiteTemplate/TirrentGlobal/assets/img/gallery/5.png",
      5: "SiteTemplate/TirrentGlobal/assets/img/gallery/6.png",
      6: "SiteTemplate/TirrentGlobal/assets/img/gallery/Riders.jpeg",
    },
    {
      1: "SiteTemplate/TirrentGlobal/assets/img/gallery/7.png",
      2: "SiteTemplate/TirrentGlobal/assets/img/gallery/8.png",
      3: "SiteTemplate/TirrentGlobal/assets/img/gallery/9.png",
      4: "SiteTemplate/TirrentGlobal/assets/img/gallery/10.png",
      5: "SiteTemplate/TirrentGlobal/assets/img/gallery/11.png",
      6: "SiteTemplate/TirrentGlobal/assets/img/gallery/12.png",
    },
  ];
  const [renderImg, setRenderImg] = useState([dataImg[0]]);
  const [toggle, setToggle] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  const Toggle = () => setToggle((prv) => !prv);
  const load = () => {
    const length = renderImg.length;
    setRenderImg((prv) => [...prv, { ...dataImg[length] }]);
  };
  const modalBig = (e) => {
    if (e.target?.currentSrc) {
      Toggle();
      setCurrentImg(e.target.currentSrc);
    }
  };
  return (
    <>
      <Header />
      <div className="page-title-area gallery-banner">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("Gallery.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("Gallery.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("Gallery.description")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("Gallery.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="offer-area pt-100 pb-100">
        <div className="container our-technology">
          <div className="section-title">
            <h2>{t("Gallery.heading")}</h2>
          </div>

          <section class="gallery">
            <div class="container1">
              {renderImg &&
                renderImg?.map((imgUrl) => {
                  return (
                    <div
                      key={imgUrl[1]}
                      className="grid"
                      onClick={(e) => modalBig(e)}
                    >
                      {" "}
                      <div class="column-xs-12 column-md-4">
                        <img className="img1" src={imgUrl[1]} />
                      </div>
                      <div class="column-xs-12 column-md-4">
                        <img className="img1" src={imgUrl[2]} />
                      </div>
                      <div class="column-xs-12 column-md-4">
                        <img className="img1" src={imgUrl[3]} />
                      </div>
                      <div class="column-xs-12 column-md-6">
                        <img className="img1" src={imgUrl[4]} />
                      </div>
                      <div class="column-xs-12 column-md-6">
                        <img className="img1" src={imgUrl[5]} />
                      </div>
                      <div class="column-xs-12 column-md-12 mb-4">
                        <img className="img1" src={imgUrl[6]} />
                      </div>
                    </div>
                  );
                })}
              {renderImg.length !== dataImg.length && (
                <div
                  className="m-4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    className="cmn-btn"
                    style={{ border: "none" }}
                    onClick={load}
                  >
                    Load More
                    <i
                      className="fa-solid fa-chevron-right"
                      style={{ marginLeft: "8px" }}
                    ></i>
                  </button>
                </div>
              )}
            </div>
          </section>
          <div className="section-title">
            <h2>Videos</h2>
          </div>
          <section class="gallery">
            <div class="container1">
              <div class="grid">
                <div class="column-xs-12 column-md-4">
                  <iframe
                    width="100%"
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/I7iP5ZFRn90?si=3_WmLimGztm-qa3T"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="column-xs-12 column-md-4">
                  <iframe
                    width="100%"
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/bbC-CuV7Pcs?si=3_WmLimGztm-qa3T"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="column-xs-12 column-md-4">
                  <iframe
                    width="100%"
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/oeGY0g0dQKA?si=3_WmLimGztm-qa3T"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="column-xs-12 column-md-6">
                  <iframe
                    width="100%"
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/s9o-FsrHCkI?si=3_WmLimGztm-qa3T"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="column-xs-12 column-md-6">
                  <iframe
                    width="100%"
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/DAgbjHsBKsM?si=3_WmLimGztm-qa3T"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="column-xs-12 column-md-4">
                  <iframe
                    width="100%"
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/H2NUscHQMWA?si=3_WmLimGztm-qa3T"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="column-xs-12 column-md-4">
                  <iframe
                    width="100%"
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/rYMo3qBngkU?si=3_WmLimGztm-qa3T"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* </div> */}
      </section>
      <Footer />
      {toggle && (
        <Modal show={toggle} close={Toggle}>
          <img src={currentImg} />
        </Modal>
      )}
    </>
  );
}

export default Gallery;
