import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation(["About"]);
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area title-area-about">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("AboutHeading.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("AboutHeading.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("AboutHeading.sub")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("AboutHeading.description")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="offer-area mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div
                className="offer-item"
                style={{ borderTop: "5px solid --var-main-color" }}
              >
                <div className="offer-top">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/3.png"
                    alt="Offer"
                    width="150px"
                  />
                </div>
                <div className="offer-bottom" style={{ marginTop: "20px" }}>
                  <h3>{t("values.mission.heading")}</h3>
                  <p>
                  {t("values.mission.description")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div
                className="offer-item"
                style={{ borderTop: "5px solid --var-main-color" }}
              >
                <div className="offer-top">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/1.png"
                    alt="Offer"
                    width="150px"
                  />
                </div>
                <div className="offer-bottom" style={{ marginTop: "20px" }}>
                  <h3>{t("values.vision.heading")}</h3>
                  <p>
                  {t("values.vision.description")}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div
                className="offer-item"
                style={{ borderTop: "5px solid --var-main-color" }}
              >
                <div className="offer-top">
                  <img
                    src="/SiteTemplate/TirrentGlobal/assets/img/2.png"
                    alt="Offer"
                    width="165px"
                  />
                </div>
                <div className="offer-bottom">
                  <h3>{t("values.values.heading")} </h3>
                  <p>
                  {t("values.values.description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section">
        <div className="container" style={{ position: "relative" }}>
          <div className={`${t("class.sm-oil-image")}`}>
            <img src="/SiteTemplate/TirrentGlobal/assets/img/aboutSide.jpg" />
          </div>
          <div className="row mt-5 mb-5">
            <div
              className="col-lg-8 services-heading american-llc"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="services-number">
                {/* <h1 className="services-number-text">01</h1> */}
              </div>
              <div className="services-content">
                <div
                  className="services-number"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h1 className="services-number-text">01</h1>
                  <h1 className="services-number-text-text">{t("whoweare.heading")}</h1>
                </div>
                <p>
                {t("whoweare.line1")}
                </p>
                <p>
                {t("whoweare.line2")}
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 american-img "
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src="/SiteTemplate/TirrentGlobal/assets/img/barrel2.png" />
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4  "
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/OurComnany.png"
                width="100%"
              />
            </div>
            <div
              className="col-lg-8 services-heading"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="services-content">
                <div
                  className="services-number"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h1 className="services-number-text">02</h1>
                  <h1 className="services-number-text-text">{t("ourcompany.heading")}</h1>
                </div>
                <p>
                {t("ourcompany.line1")}
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-8 services-heading american-llc"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="services-content">
                <div
                  className="services-number"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <h1 className="services-number-text">03</h1>
                  <h1 className="services-number-text-text">{t("headquarter.heading")}</h1>
                </div>

                <p>
                {t("headquarter.line1")}
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 american-img"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src="/SiteTemplate/TirrentGlobal/assets/img/headquater.png"
                width="100%"
              />
            </div>
          </div>
          <div
            className="mt-5 mb-5"
            style={{ display: "flex", alignItems: "center" }}
          ></div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
