import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function ResearchPage() {
  const { t } = useTranslation(["research"]);
  const { pathname } = useLocation();
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area title-area-research">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("ResearchHeading.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("ResearchHeading.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("ResearchHeading.sub")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("ResearchHeading.description")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-area">
        <div className="container">
          <div className="row align-iems-center">
            <div className="col-lg-6">
              <div className="section-title">
                <span className="sub-title">{t("researchHeading.title1")}</span>
                <h2>{t("researchHeading.title2")}</h2>
              </div>
              <div className="about-content">
                <p>{t("researchHeading.line1")}</p>
                <p>{t("researchHeading.line2")}</p>
                <ul>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>
                    {t("researchHeading.subheading1.title")}
                    <p className="para">
                      {t("researchHeading.subheading1.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading2.title")}
                    <p className="para">
                      {t("researchHeading.subheading2.description")}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading3.title")}
                    <p className="para">
                      {t("researchHeading.subheading3.description")}
                    </p>
                  </li>

                  <li>
                    <i className="fa fa-check" style={{ fontSize: "10px" }}></i>{" "}
                    {t("researchHeading.subheading4.title")}
                    <p className="para">
                      {t("researchHeading.subheading4.description")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 research-development">
              <div className="about-img-wrap">
                <div className="about-img-slider owl-theme">
                  <div className="about-img-item">
                    <img
                      src="/SiteTemplate/TirrentGlobal/assets/img/tecno-page.png"
                      alt="About"
                    />
                  </div>
                </div>

                <div className="about-year">
                  <h2>
                    <span>{t("ResearchHeading.search")}</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="project-details-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="project-details-item">
                <div className="project-details-img">
                  <h2 className="product-r-d">{t("Product.title")}</h2>
                  <p>{t("Product.line1")}</p>
                  <p> {t("Product.line2")}</p>
                  <h2 className="product-r-d">{t("engine.title")}</h2>

                  <p>{t("engine.line1")}</p>
                  <p>{t("engine.line2")}</p>
                  <p>{t("engine.line3")}</p>
                  <div className="row">
                    <div className="col-sm-6 col-lg-6">
                      <ul>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point1")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point2")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point3")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point4")}
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <ul>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point5")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point6")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point7")}
                        </li>
                        <li>
                          <i className="fa fa-check-square"></i>{" "}
                          {t("engine.subheading.Point8")}
                          Monitoring
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="project-details-content">
                  <div className="row">
                    <div className="col-sm-6 col-lg-6">
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/research1.jpg"
                        alt="Project Details"
                      />
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <img
                        src="https://img.freepik.com/free-photo/oil-dropping-laboratory-science-experiments-formulating-chemical-medical-research-quality-control-petroleum-industry-products-concept_175175-665.jpg?w=2000"
                        alt="Project Details"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="project-details-item">
                <div className="project-details-img">
                  <h2 className="product-r-d">
                  {t("Complications.title")}
                  </h2>

                  <p>
                    <span className="research-span">{t("Complications.subheading1")}</span>:
                    {t("Complications.line1")}
                  </p>
                  <p>
                    <span className="research-span">{t("Complications.subheading2")}</span>:
                    {t("Complications.line2")}
                  </p>
                  <p>
                    <span className="research-span">{t("Complications.subheading3")}</span>:
                    {t("Complications.line3")}
                  </p>
                  <p>
                    <span className="research-span">
                    {t("Complications.subheading4")}
                    </span>
                    {t("Complications.line4")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="project-details-item">
                <div className="project-details-img">
                  <h2 className="product-r-d">
                  {t("Sectors.title")}
                  </h2>

                  <p>
                    <span className="research-span"> {t("Sectors.subheading1")}</span>: {t("Sectors.line1")}
                  </p>
                  <p>
                    <span className="research-span">{t("Sectors.subheading2")}</span>:{t("Sectors.line2")}
                  </p>
                  <p>
                    <span className="research-span">{t("Sectors.subheading3")}</span>:
                    {t("Sectors.line3")}
                  </p>
                  <p>
                    <span className="research-span">{t("Sectors.subheading4")}</span>:
                    {t("Sectors.line4")}
                  </p>
                  <p>
                    <span className="research-span">{t("Sectors.subheading5")}</span>:
                    {t("Sectors.line5")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ResearchPage;
