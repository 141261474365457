import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs5() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog5"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area blogss">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("blog5.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("blog5.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("blog5.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("blog5.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog5.heading")}</h2>
                  <p>{t("blog5.p1")}</p>
                  <p>{t("blog5.p2")}</p>

                  <p>{t("blog5.p3")}</p>
                  <p>{t("blog5.p4")}</p>
                  <p>{t("blog5.p5")}</p>
                  <p>{t("blog5.p6")}</p>

                  <ul className="blog1-point">
                    <li>{t("blog5.point1.heading")}</li>

                    <p>{t("blog5.point1.p1")}</p>

                    <p>{t("blog5.point1.p2")}</p>

                    <p>{t("blog5.point1.p3")}</p>
                    <p>{t("blog5.point1.p4")}</p>
                    <li>{t("blog5.point2.heading")}</li>
                    <p>{t("blog5.point2.p1")}</p>
                    <p>{t("blog5.point2.p2")}</p>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <h1 style={{ color: "--var-main-color" }}>
                {t("blog5.latestblog.title")}
              </h1>
              <hr />
              <div className="blog-item">
                <Link to="/blog-1">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.latestblog.img1"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-1">{t("blog5.latestblog.heading1")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-1">
                    {t("blog5.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-2">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.latestblog.img2"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-2">{t("blog5.latestblog.heading2")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-2">
                    {t("blog5.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-3">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.latestblog.img3"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-3">{t("blog5.latestblog.heading3")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-3">
                    {t("blog5.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-4">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.latestblog.img4"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-4">{t("blog5.latestblog.heading4")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-4">
                    {t("blog5.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-6">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.latestblog.img6"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-6">{t("blog5.latestblog.heading6")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-6">
                    {t("blog5.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-7">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.latestblog.img7"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-7">{t("blog5.latestblog.heading7")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-7">
                    {t("blog5.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-8">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.latestblog.img8"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-8">{t("blog5.latestblog.heading8")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-8">
                    {t("blog5.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-9">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog5.latestblog.img9"
                    )}`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-9">{t("blog5.latestblog.heading9")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-9">
                    {t("blog5.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blogs5;
