import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Blogs6() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blog6"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area blogss">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("blog6.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("blog6.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("blog6.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("blog6.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog6.heading")}</h2>
                  <p>{t("blog6.p1")}</p>
                  <ul>
                    <li>{t("blog6.poin1")}</li>
                    <li>{t("blog6.poin2")}</li>
                    <li>{t("blog6.poin3")}</li>
                    <li>{t("blog6.poin4")}</li>
                  </ul>
                  <p>{t("blog6.p2")}</p>

                  <p>{t("blog6.p3")}</p>
                  <p>{t("blog6.p4")}</p>
                  <h4 className="blog6-subheading">{t("blog6.ques")}</h4>
                  <p>{t("blog6.para1")}</p>
                  <ol className="blog6-point">
                    <li> {t("blog6.1.head")}</li>

                    <p>{t("blog6.1.p1")}</p>
                    <ul className="subs-headings">
                      <li> {t("blog6.1.poin1")}</li>
                      <li> {t("blog6.1.poin2")}</li>
                      <li> {t("blog6.1.poin3")}</li>
                    </ul>
                    <p>{t("blog6.1.p2")}</p>
                    <li>{t("blog6.2.head")}</li>

                    <p>{t("blog6.2.p1")}</p>

                    <ul className="subs-headings">
                      <li>{t("blog6.2.poin1")}</li>
                      <li>{t("blog6.2.poin2")}</li>
                      <li>{t("blog6.2.poin3")}</li>
                    </ul>
                    <p></p>

                    <li>{t("blog6.3.head")}</li>

                    <p>{t("blog6.3.p")}</p>

                    <ul className="subs-headings">
                      <li>{t("blog6.3.poin1")}</li>
                    </ul>

                    <h4 className="blog6-subheading">{t("blog6.head")}</h4>
                    <p>{t("blog6.para2")}</p>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <h1 style={{ color: "--var-main-color" }}>
                {t("blog6.latestblog.title")}
              </h1>
              <hr />

              <div className="blog-item">
                <Link to="/blog-2">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.latestblog.img2"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-2">{t("blog6.latestblog.heading2")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-2">
                    {t("blog6.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-3">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.latestblog.img3"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-3">{t("blog6.latestblog.heading3")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-3">
                    {t("blog6.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-4">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.latestblog.img4"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-4">{t("blog6.latestblog.heading4")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-4">
                    {t("blog6.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-5">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.latestblog.img5"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-5">{t("blog6.latestblog.heading5")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-5">
                    {t("blog6.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-7">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.latestblog.img7"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-7">{t("blog6.latestblog.heading7")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-7">
                    {t("blog6.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-8">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.latestblog.img8"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-8">{t("blog6.latestblog.heading8")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-8">
                    {t("blog6.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-9">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog6.latestblog.img9"
                    )}`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-9">{t("blog6.latestblog.heading9")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-9">
                    {t("blog6.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Blogs6;
