import React, { useEffect } from "react";
import AOS from "aos";
import { Link, useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { useTranslation } from "react-i18next";
function Blogs3() {
  const { pathname } = useLocation();
  const { t } = useTranslation(["Blogs3"]);

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area blogss">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("blog3.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("blog3.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("blog3.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("blog3.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog3.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog3.heading1")}</h2>
                  <p>{t("blog3.line1")}</p>
                  <p>{t("blog3.line2")}</p>

                  <p>{t("blog3.line3")}</p>
                  <p>{t("blog3.line4")}</p>
                  <p>{t("blog3.line5")}</p>

                  <ol className="blog1-point">
                    <h3> {t("point.title")}</h3>
                    <p>{t("point.line")}</p>
                    <li>{t("point.point1")}</li>

                    <p>{t("point.line1")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/improving-lub.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("point.point2")}</li>

                    <p>{t("point.line2")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/less-friction.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("point.point3")}</li>

                    <p>{t("point.line3")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/engine-heat.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("point.point4")}</li>

                    <p>{t("point.line4")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/loadBalancing.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("point.point5")}</li>

                    <p>{t("point.line5")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/cleaning.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>

                    <li>{t("point.point6")}</li>

                    <p>{t("point.line6")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/oxidation.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                    <li>{t("point.point7")}</li>

                    <p>{t("point.line7")}</p>
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src="/SiteTemplate/TirrentGlobal/assets/img/vibration.png"
                        width="40%"
                        className="blogs-mini-img"
                      />
                    </div>
                  </ol>
                  <ol className="blog1-point">
                    <h3>{t("char.title")}</h3>
                    <p>{t("char.titleline1")}</p>
                    <p>{t("char.titleline2")}</p>
                    <p>{t("char.titleline3")}</p>
                    <li> {t("char.point1")}</li>
                    <p>{t("char.line1")}</p>
                    <li>{t("char.point2")}</li>
                    <p>{t("char.line2")}</p>
                    <li>{t("char.point3")}</li>
                    <p>{t("char.line3")}</p>
                    <li>{t("char.point4")}</li>
                    <p>{t("char.line4")}</p>
                    <li>{t("char.point5")}</li>
                    <p>{t("char.line5")}</p>
                    <h3 style={{ color: "#F89F31", fontWeight: "600" }}>
                      {t("char.conclusion")}
                    </h3>
                    <p> {t("char.line6")}</p>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <h1 style={{ color: "--var-main-color" }}>
                {" "}
                {t("latestblog.title")}
              </h1>
              <hr />

              <div className="blog-item">
                <Link to="/blog-1">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img1"
                    )}.png`}
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-1">{t("latestblog.heading1")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-1">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-2">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img2"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-2">{t("latestblog.heading2")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-2">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-4">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img4"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-4"> {t("latestblog.heading4")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-4">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-5">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img5"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-5">{t("latestblog.heading5")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-5">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-6">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img6"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-6">{t("latestblog.heading6")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-6">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-7">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img7"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-7">{t("latestblog.heading7")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-7">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-8">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img8"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-8">{t("latestblog.heading8")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-8">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-9">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "latestblog.img9"
                    )}`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-9">{t("latestblog.heading9")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-9">
                    {t("latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogs3;
