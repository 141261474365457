import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import { useTranslation } from "react-i18next";

function ExchangeAndCancel() {
  const { t } = useTranslation(["CancellationPolicy"]);

  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header />
      <div className="page-title-area terms-condition">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("canclepolicy.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("canclepolicy.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("canclepolicy.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("canclepolicy.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mission-vision offer-area-four pt-100 pb-70">
        <div className="container">
          <div className="row privacyContent">
            <div className="col-sm-12 col-lg-10 ">
              <h2>{t("canclepolicy.heading")}</h2>
              <p>{t("canclepolicy.line1")}</p>
              <ul>
                <li>{t("canclepolicy.line1.1")}</li>
                <li>{t("canclepolicy.line1.2")}</li>
                <li>{t("canclepolicy.line1.3")}</li>
              </ul>

              <p>{t("canclepolicy.line2")}</p>

              <p>{t("canclepolicy.line3")}</p>

              <p>{t("canclepolicy.line4")}</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ExchangeAndCancel;
