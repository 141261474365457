import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function ProductBooster() {
  const { t } = useTranslation(["Product"]);
  const navigate = useNavigate();

  return (
    <>
      {" "}
      <div className="row mt-5">
        <div
          className="col-lg-5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            className="container-product"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <div className=""> */}
            {/* <div className="imgBx"> */}
            <img
              src="/SiteTemplate/TirrentGlobal/assets/img/abarrel2.png"
              alt="img"
              width="70%"
            />
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className="container-product mt-5">
            <div
              className="box__link button-animation"
              onClick={() => navigate("/product-details")}
            >
              {t("Product1.button.buynow")}
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className="col-lg-7 tirrent-booster">
          <h2>
            <span className="product-booster"> {t("Product1.span")} </span>
          </h2>
          <h2> {t("Product1.title")} </h2>
          <p>{t("Product1.description")}</p>
          <p className="mt-4">{t("Product1.description1")}</p>
          <div className="job-area1">
            <div className="job-content1" style={{ padding: "10px" }}>
              <div className="row tec">
                <div className="col-md-6">
                  <div className="techa">
                    <i className="fa-solid fa-check"></i>
                    {t("Product1.technical.one")}{" "}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="techa">
                    {" "}
                    <i className="fa-solid fa-check"></i>
                    {t("Product1.technical.two")}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="techa">
                    <i className="fa-solid fa-check"></i>
                    {t("Product1.technical.three")}{" "}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="techa">
                    <i className="fa-solid fa-check"></i>
                    {t("Product1.technical.four")}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4 left-content ">
        <div className="col-md-6 mb-3">
          <div className="">
            <h5>
              <span className="product-booster">
                {t("Product1.keyfeatures.title")}
              </span>
            </h5>
            <ul>
              <div className="row">
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.keyfeatures.one")}
                      </h6>
                      <p>{t("Product1.keyfeatures.paraone")}</p>
                    </div>
                  </li>
                </div>
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.keyfeatures.two")}
                      </h6>
                      <p>{t("Product1.keyfeatures.paratwo")}</p>
                    </div>
                  </li>
                </div>{" "}
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.keyfeatures.three")}
                      </h6>
                      <p>{t("Product1.keyfeatures.parathree")}</p>
                    </div>
                  </li>
                </div>{" "}
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.keyfeatures.four")}
                      </h6>
                      <p>{t("Product1.keyfeatures.parafour")}</p>
                    </div>
                  </li>
                </div>{" "}
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.keyfeatures.five")}
                      </h6>
                      <p>{t("Product1.keyfeatures.parafive")}</p>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="col-md-6  mb-3">
          <div className="">
            <h5>
              <span className="product-booster">
                {t("Product1.benefits.title")}
              </span>
            </h5>
            <ul>
              <div className="row">
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.benefits.one")}
                      </h6>
                      <p>{t("Product1.benefits.paraone")}</p>
                    </div>
                  </li>
                </div>
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.benefits.two")}
                      </h6>
                      <p>{t("Product1.benefits.paratwo")}</p>
                    </div>
                  </li>
                </div>
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.benefits.three")}
                      </h6>
                      <p>{t("Product1.benefits.parathree")}</p>
                    </div>
                  </li>
                </div>
                <div className="col-md-12 key-features-product">
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="fa-solid fa-droplet"></i>{" "}
                    <div>
                      <h6
                        style={{
                          marginBottom: "0",
                          color: "#f99f36",
                        }}
                      >
                        {" "}
                        {t("Product1.benefits.four")}
                      </h6>
                      <p>{t("Product1.benefits.parafour")}</p>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
        {/*  */}
      </div>
    </>
  );
}

export default ProductBooster;
