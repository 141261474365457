import React from "react";
import Header from "../Shared/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../Shared/Footer";

function Blogs8() {
  const { t } = useTranslation(["Blogs8"]);

  return (
    <>
      <Header />
      <div className="page-title-area blogss">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("blog8.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("blog8.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("blog8.span")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("blog8.title")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 main-blog">
              <div className="blog-details-item">
                <div className="blog-details-name">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.img"
                    )}.png`}
                    width="100%"
                  />

                  <h2>{t("blog8.heading")}</h2>
                  <p>{t("blog8.para")}</p>

                  <ul className="blog6-point">
                    <li> {t("blog8.point1")}</li>
                    <p>{t("blog8.para1")}</p>

                    <li> {t("blog8.point2")}</li>
                    <p>{t("blog8.para2")}</p>

                    <li> {t("blog8.point3")}</li>
                    <p>{t("blog8.para3")}</p>

                    <li> {t("blog8.point4")}</li>
                    <p>{t("blog8.para4")}</p>

                    <li> {t("blog8.point5")}</li>
                    <p>{t("blog8.para5")}</p>

                    <li> {t("blog8.point6")}</li>
                    <p>{t("blog8.para6")}</p>
                    <li> {t("blog8.point7")}</li>
                    <p>{t("blog8.para7.1")}</p>
                    <p>{t("blog8.para7.2")}</p>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <h1 style={{ color: "--var-main-color" }}>
                {t("blog8.latestblog.title")}
              </h1>
              <hr />
              <div className="blog-item">
                <Link to="/blog-1">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.latestblog.img1"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-1">{t("blog8.latestblog.heading1")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-1">
                    {t("blog8.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-2">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.latestblog.img2"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-2">{t("blog8.latestblog.heading2")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-2">
                    {t("blog8.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-3">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.latestblog.img3"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-3">{t("blog8.latestblog.heading3")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-3">
                    {t("blog8.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-4">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.latestblog.img4"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-4">{t("blog8.latestblog.heading4")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-4">
                    {t("blog8.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-5">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.latestblog.img5"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-5">{t("blog8.latestblog.heading5")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-5">
                    {t("blog8.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-6">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.latestblog.img6"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-6">{t("blog8.latestblog.heading6")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-6">
                    {t("blog8.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>

              <div className="blog-item">
                <Link to="/blog-7">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.latestblog.img7"
                    )}.png`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-7">{t("blog8.latestblog.heading7")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-7">
                    {t("blog8.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
              <div className="blog-item">
                <Link to="/blog-9">
                  <img
                    src={`/SiteTemplate/TirrentGlobal/assets/img/${t(
                      "blog8.latestblog.img9"
                    )}`}
                    alt="Blog"
                  />
                </Link>
                <div className="blog-inner1">
                  <h3>
                    <Link to="/blog-9">{t("blog8.latestblog.heading9")}</Link>
                  </h3>
                  <Link className="blog-link1" to="/blog-9">
                    {t("blog8.latestblog.readmore")}
                    <i className="fa fa-angle-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs8;
