import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import App from "./App";
import "./SiteTemplates/TirrentGlobal/i18n/i18n.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <React.Suspense fallback={"...loading"}>
      <App />
    </React.Suspense>
  </React.StrictMode>
);
