import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import Modal from "../Shared/Modal";
import { useTranslation } from "react-i18next";
import ProductBooster from "./ProductBooster";
import ProductMileage from "./ProductMileage";
import ProductSpray from "./ProductSpray";
import ProductGrease from "./ProductGrease";

function ProductPage() {
  const {id}=useParams()
  const [tabIndex, setTabIndex] = useState(id);
  const { t } = useTranslation(["Product"]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const Components = [
    <ProductBooster />,
    <ProductMileage />,
    <ProductSpray />,
    <ProductGrease />,
  ];
  
  console.log(id,"id in paremsss")
  const handleClickOnTab = (e) => {
    setTabIndex(e.target.id);
  };
  console.log(tabIndex, "tab");
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header />
      <div className="page-title-area title-area-product">
        <div className="d-table">
          <div className="d-table-cell">
            <h2 className="policy">{t("ProductHeading.title")}</h2>
            <div className="container">
              <div className="title-item">
                <h2>{t("ProductHeading.title")}</h2>
                <ul>
                  <li>
                    <Link to="/">{t("ProductHeading.sub")}</Link>
                  </li>
                  <li>
                    <span>/</span>
                  </li>
                  <li>{t("ProductHeading.description")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="privacy-area pt-100">
        <div className="container" style={{ position: "relative" }}>
          <div className="privacy-item">
            <div className="section-title" style={{ textAlign: "left" }}>
              <span
                className={
                  tabIndex == 0
                    ? "sub-title payment-menus selected"
                    : "sub-title payment-menus"
                }
                id='0'
                onClick={(e) => handleClickOnTab(e)}
              >
                Tirrent Booter
              </span>
              &nbsp; / &nbsp;
              <span
                className={
                  tabIndex == 1
                    ? "sub-title payment-menus selected"
                    : "sub-title payment-menus "
                }
                id='1'
                onClick={(e) => handleClickOnTab(e)}
              >
                Tirrent Mileage Bootser
              </span>
              &nbsp; / &nbsp;
              <span
                className={
                  tabIndex == 2
                    ? "sub-title payment-menus selected"
                    : "sub-title payment-menus"
                }
                id='2'
                onClick={(e) => handleClickOnTab(e)}
              >
                Tirrent Magic Spray
              </span>
              &nbsp; / &nbsp;
              <span
                className={
                  tabIndex == 3
                    ? "sub-title payment-menus selected"
                    : "sub-title payment-menus"
                }
                id='3'
                onClick={(e) => handleClickOnTab(e)}
              >
                Tirrent Magic Grease
              </span>
            </div>
            {Components[tabIndex]}
          </div>
        </div>
      </section>
      <div className="job-area">
        <div className="job-shape">
          <img
            src="/SiteTemplate/TirrentGlobal/assets/img/4572173-removebg-preview (1).png"
            alt="Job"
            width="70%"
          />
        </div>
        <div className="container-fluid">
          <div className="row m-0">
            <div className="col-lg-6">
              <div className="job-img">
                <img
                  src="https://www.pwc.ca/-/media/airtime-blog/oil-analysis-technology-update-header.ashx"
                  alt="Job"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="job-content">
                <div className="section-title">
                  <h2>{t("booster.title")}</h2>
                </div>
                <p>{t("booster.description")}</p>
                <ul>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    {t("booster.point1")}{" "}
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    {t("booster.point2")}
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    {t("booster.point3")}{" "}
                  </li>
                  <li>
                    <i className="fa-solid fa-check"></i>
                    {t("booster.point4")}{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ProductPage;
